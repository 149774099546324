import PropTypes from 'prop-types'

const Text = ({ children, className, component, size, color, weight }) => {
  const classNameText =
    'text' +
    ` text_color_${color}` +
    (size ? ` text_size_${size}` : '') +
    (weight ? ` text_weight_${weight}` : '') +
    (className ? ` ${className}` : '')

  if (component === 'span') {
    return <span className={classNameText}>{children}</span>
  } else {
    return <p className={classNameText}>{children}</p>
  }
}

Text.defaultProps = {
  component: 'p',
  color: 'default',
  size: 'md',
}

Text.propTypes = {
  component: PropTypes.oneOf(['p', 'span']),
  className: PropTypes.string,
  children: PropTypes.node,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'inherit']),
  color: PropTypes.oneOf([
    'default',
    'primary',
    'secondary',
    'dark',
    'light-gray',
    'gray-03',
    'positive',
    'negative',
    'gradient',
    'gold',
    'plum',
    'rainbow',
  ]),
  weight: PropTypes.oneOf(['medium', 'bold', 'semi-bold']),
}

export default Text
