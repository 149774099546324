import Image from 'next/image'
import Link from 'next/link'

// import logoAnimated from '/public/logo-animated.gif'
import logoLabel from '/public/logo-label.svg'
import logoMain from '/public/logo-main.svg'

const Logo = ({ short = false }) => {
  return (
    <div className={`logo${short ? ' logo--short' : ''}`}>
      {short && (
        <Link href='/'>
          <a>
            <Image src={logoMain} height={30} width={30} />
          </a>
        </Link>
      )}
      {!short && (
        <Link href='/'>
          <a className='logo__container'>
            <Image src={logoMain} height={30} width={30} />
            <Image src={logoLabel} height={31} width={100} />
          </a>
        </Link>
      )}
    </div>
  )
}

export default Logo
