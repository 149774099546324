import { Toaster, ToastBar, toast } from 'react-hot-toast'

export const MessageContainer = () => {
  return (
    <Toaster
      position='top-center'
      containerClassName='message-container'
      toastOptions={{
        className: 'message',
        duration: 5000,
        success: {
          duration: 3000,
          icon: <img src='/icons/toast-success.svg' alt='success' />,
        },
        error: {
          duration: 5000,
          icon: <img src='/icons/toast-error.svg' alt='error' />,
        },
        loading: {
          duration: 5000,
          icon: <img src='/icons/toast-loading.svg' alt='loading' />,
        },
      }}
    >
      {(t) => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <>
              {icon}
              {message}
              {t.type !== 'loading2' && (
                <div
                  onClick={() => {
                    console.log(t)
                    toast.dismiss(t.id)
                  }}
                  className='message__close'
                >
                  <div />
                  <div />
                </div>
              )}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  )
}
