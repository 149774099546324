import PropTypes from 'prop-types'

import SkeletonAvatar from './SkeletonAvatar'
import SkeletonInput from './SkeletonInput'

const Skeleton = ({ children, className, active, loading }) => {
  const classNameSkeleton =
    'skeleton' +
    (className ? ` ${className}` : '') +
    (loading === true ? ' skeleton--loading' : '') +
    (active === true && loading === true ? ' skeleton--active' : '')

  return <div className={classNameSkeleton}>{children}</div>
}

Skeleton.defaultProps = {
  active: true,
  loading: false,
}

Skeleton.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.any.isRequired,
  loading: PropTypes.bool,
}

Skeleton.Avatar = SkeletonAvatar
Skeleton.Input = SkeletonInput

export default Skeleton
