import { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import Script from 'next/script'

import Header from '/src/components/@common.blocks/Header'
import Footer from '/src/components/@common.blocks/Footer'
import SEO from '/src/components/@common.blocks/Layout/SEO'
import { MessageContainer } from '/src/components/@common.blocks/Message'
import CookieMessage from '/src/components/@common.blocks/CookieMessage'
import DeviceContext from '/src/contexts/DeviceContext'

const Layout = ({
  children,
  title,
  description,
  previewImage,
  className,
  isLoading,
  isHome,
  footer,
  header,
  headerTheme,
}) => {
  const { setIsMobile } = useContext(DeviceContext)

  useEffect(() => {
    const handlerOnResize = () => {
      if (typeof document !== 'undefined') {
        setIsMobile(document.documentElement.clientWidth <= 768)
      }
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handlerOnResize)
      handlerOnResize()
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handlerOnResize)
      }
    }
  }, [])

  const classNameLayout = 'layout' + (className ? ` ${className}` : '')

  return (
    <div>
      {header && <Header theme={headerTheme} dynamicSearch={isHome} />}
      <main className={classNameLayout}>
        <SEO
          title={title}
          description={description}
          image={previewImage || '/preview.jpeg'}
        />
        <Script
          src='https://www.googletagmanager.com/gtag/js?id=G-CHDFH9T3E9'
          strategy='afterInteractive'
        />
        <Script id='google-analytics' strategy='afterInteractive'>
          {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){window.dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'G-CHDFH9T3E9');
            `}
        </Script>
        <MessageContainer />
        <CookieMessage />
        {children}
      </main>
      {footer && <Footer />}
    </div>
  )
}

Layout.defaultProps = {
  title: 'Checking NFT rarity',
  isLoading: false,
  header: true,
  footer: true,
  isHome: false,
}

Layout.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  previewImage: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  header: PropTypes.bool,
  footer: PropTypes.bool,
  isHome: PropTypes.bool,
}

export default Layout
