import PropTypes from 'prop-types'

const Button = ({
  children,
  onClick,
  color,
  size,
  type,
  disabled,
  className,
}) => {
  const classNameButton =
    'button' +
    (color ? ` button_color_${color}` : '') +
    (size ? ` button_size_${size}` : '') +
    (className ? ` ${className}` : '')

  return (
    <button
      type={type}
      disabled={disabled}
      className={classNameButton}
      onClick={disabled ? undefined : onClick}
    >
      {children}
    </button>
  )
}

Button.defaultProps = {
  color: 'default',
  type: 'button',
  disabled: false,
  size: 'md',
}

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['xs', 'sm', 'md']),
  color: PropTypes.oneOf([
    'default',
    'primary',
    'secondary',
    'link',
    'light',
    'gold',
    'plum',
  ]),
  onClick: PropTypes.func,
}

export default Button
