import { useState, useContext, useEffect, useRef } from 'react'
import Router from 'next/router'
import Link from 'next/link'
import PropTypes from 'prop-types'

import SessionContext from '/src/hooks/useSession'
import DeviceContext from '/src/contexts/DeviceContext'
import EthereumContext from '/src/hooks/useEthereum'

import Logo from '/src/components/@common.blocks/Logo'
import Navigation from '/src/components/@common.blocks/Navigation'
import NavigationMobile from '/src/components/@common.blocks/NavigationMobile'
import Button from '/src/components/@common.blocks/Button'
import Search from '/src/components/@common.blocks/Search'
import MenuBurger from '/src/components/@common.blocks/MenuBurger'
import useOutsideClick from '/src/hooks/useOutsideClick'
import { cutAddress } from '/src/helpers/formatter'
import { logout } from '/src/helpers/auth'
import onMenuOpen from '/src/helpers/onMenuOpen'

const SCROLL_TOP_STICKY = 20

const Header = ({ theme, dynamicSearch = false }) => {
  const [menuOpen, setMenuOpen] = useState(false)

  const { user, setUser } = useContext(SessionContext)
  const { isMobile } = useContext(DeviceContext)
  const { disconnectWallet } = useContext(EthereumContext)

  const headerClassName =
    `header header_theme_${theme}` +
    (!dynamicSearch ? ' header--search-visible' : '')

  const menuRef = useRef(null)
  useOutsideClick(menuRef, () => setMenuOpen(false))

  const onMenuToggle = (e) => {
    setMenuOpen(!menuOpen)
  }

  const onLogout = async () => {
    disconnectWallet()
    setUser(null)
    await logout(true)
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', isSticky)
    }
    return () => {
      window.removeEventListener('scroll', isSticky)
    }
  }, [])

  useEffect(() => {
    onMenuOpen(menuOpen)
    return () => onMenuOpen(false)
  }, [menuOpen])

  const isSticky = () => {
    const header = document.querySelector('.header')
    if (header) {
      const scrollTop = window.scrollY
      scrollTop >= SCROLL_TOP_STICKY
        ? header.classList.add('header--sticky')
        : header.classList.remove('header--sticky')

      if (dynamicSearch) {
        const elSearch = document.getElementById('explore')
        if (elSearch) {
          if (
            scrollTop >=
            elSearch.offsetTop + elSearch.getBoundingClientRect().height
          ) {
            if (!header.classList.contains('header--search-visible')) {
              header.classList.add('header--search-visible')
            }
          } else {
            header.classList.remove('header--search-visible')
          }
        }
      }
    }
  }

  return (
    <header className={headerClassName}>
      <div className='header__container'>
        <Logo short={isMobile} />
        {!isMobile && (
          <>
            <Search />
            <Navigation />
            <Button
              className='header__button-list'
              onClick={() => Router.replace('/get-listed')}
            >
              Get Listed
            </Button>
            {user && user.address && (
              <Button color='primary' onClick={onMenuToggle}>
                {cutAddress(user.address)}
                {menuOpen && (
                  <ul className='header__menu' ref={menuRef}>
                    <li>
                      <Link href='/account'>Profile</Link>
                    </li>
                    <li onClick={onLogout}>Logout</li>
                  </ul>
                )}
              </Button>
            )}
            {!user && (
              <Button color='primary' onClick={() => Router.replace('/login')}>
                Login
              </Button>
            )}
          </>
        )}
        {isMobile && (
          <>
            <Search responsive={true} />
            <MenuBurger isOpen={menuOpen} setOpen={onMenuToggle} />
          </>
        )}
        {isMobile && menuOpen && (
          <NavigationMobile menuOnClose={() => setMenuOpen(false)} />
        )}
      </div>
    </header>
  )
}

Header.defaultProps = {
  theme: 'default',
  dynamicSearch: false,
}

Header.propTypes = {
  theme: PropTypes.oneOf(['default', 'ghost']),
  dynamicSearch: PropTypes.bool,
}

export default Header
