import { useEffect, useState } from 'react'

import SearchResult from './SearchResult'
import Input from '/src/components/@common.blocks/Input'
import onLockScroll from '/src/helpers/onMenuOpen'

const SearchMobile = ({
  query,
  loading,
  collections,
  trending,
  assets,
  onChange,
  placeholder,
}) => {
  const [active, setActive] = useState(false)

  useEffect(() => {
    onLockScroll(active)
    return () => onLockScroll(false)
  }, [active])

  const resultOpen = (!loading && collections.length > 0) || trending.length > 0
  return (
    <div className='search-mobile'>
      <div className='search-mobile__btn' onClick={() => setActive(!active)}>
        <img src='/ui-icons/search.svg' alt='search' />
      </div>
      {active && (
        <div className='search-mobile__container'>
          <div className='search-mobile__search'>
            <Input
              onFocus={() => setActive(true)}
              value={query}
              onChange={onChange}
              className='search-mobile__search-input'
              placeholder={placeholder || 'Search the MetaVerse...'}
              autoFocus={true}
            />
            <div
              className='search-mobile__close'
              onClick={() => setActive(false)}
            >
              <div />
              <div />
            </div>
            <SearchResult
              loading={loading}
              collections={collections}
              trending={trending}
              assets={assets}
              open={active && resultOpen}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default SearchMobile
