const BlueMarkBadge = ({ children, visible }) => {
  return (
    <div>
      {children}
      {visible && (
        <img
          src='/icons/bluemark.svg'
          alt='bluemark'
          className='bluemark-badge'
        />
      )}
    </div>
  )
}

export default BlueMarkBadge
