import React from 'react'

const MenuBurger = ({ setOpen, isOpen }) => {
  return (
    <div
      className={`menu-burger${isOpen ? ' menu-burger--open' : ''}`}
      onClick={() => setOpen(!isOpen)}
    >
      <div className='menu-burger__container'>
        <div />
        <div />
        <div />
      </div>
    </div>
  )
}

export default MenuBurger
