import Link from 'next/link'
import numeral from 'numeral'
import Image from 'next/image'

import Loader from '/src/components/@common.blocks/Loader'
import Text from '/src/components/@common.blocks/Text'
import Avatar from '/src/components/@common.blocks/Avatar'
import BlueMarkBadge from '/src/components/@common.blocks/Badge/BlueMarkBadge'

import blockchainIcons from '/src/constants/blockchainIcons'
import { cutText } from '/src/helpers/formatter'

const ResultSection = ({ title, titleImg, visible = true, children }) => {
  if (!visible) return ''
  return (
    <div className='search-result__section'>
      <Text color='light-gray' weight='medium' className='search-result__title'>
        {titleImg && titleImg}
        {title}
      </Text>
      {children}
    </div>
  )
}

const ResultList = ({ items }) => {
  if (!items) return ''
  return <ul className='search-result__list'>{items}</ul>
}

const ResultCollection = ({ item }) => {
  return (
    <Link
      href={`/collections/[slug]`}
      as={`/collections/${item.slug || item.contract}`}
    >
      <a className='search-result__collection'>
        <div className='search-result__collection-name'>
          <Avatar src={item.image} alt={item.name} size='sm' />
          <BlueMarkBadge visible={item.verified}>
            <Text size='md' component='span' weight='medium'>
              {cutText(item.name, 25)}
            </Text>
          </BlueMarkBadge>
        </div>
        <div className='search-result__collection-value'>
          <Image
            src={blockchainIcons[item.blockchain]}
            alt={item.blockchain}
            className='search-result__blockchain'
            width={14}
            height={14}
          />
          <Text weight='medium' size='md' component='span' color='light-gray'>
            {numeral(item.stats?.volumeW).format('0a')}
          </Text>
        </div>
      </a>
    </Link>
  )
}

const ResultAsset = ({ item }) => {
  return (
    <Link
      href={`/collections/[slug]/[tokenId]`}
      as={`/collections/${item.slug || item.contract}/${item.tokenId}`}
    >
      <a className='search-result__asset'>
        <div className='search-result__asset-name'>
          <Avatar src={item.image} alt={item.name} size='sm' shape='square' />
          <Text size='md' component='span' weight='medium'>
            {item.name}
          </Text>
        </div>
        <Text size='md' component='span' weight='medium' color='plum'>
          {`Rank #${item.rank}`}
        </Text>
      </a>
    </Link>
  )
}

const ResultCategory = ({ item }) => {
  return (
    <div className='search-result__category'>
      <Avatar src={item.image} alt={item.name} size='sm' />
      <Text size='md' component='span' weight='medium'>
        {item.name}
      </Text>
    </div>
  )
}

const SearchResult = ({ loading, open, collections, trending, assets }) => {
  if (!open) return ''
  const isTrending = collections.length === 0 && trending.length > 0

  const rowsCollections =
    collections.length > 0
      ? collections.map((row, key) => <ResultCollection item={row} key={key} />)
      : ''

  const rowsAssets =
    assets.length > 0
      ? assets.map((row, key) => <ResultAsset item={row} key={key} />)
      : ''

  const rowsTrending =
    trending.length > 0
      ? trending.map((row, key) => <ResultCollection item={row} key={key} />)
      : ''

  return (
    <div className='search-result'>
      {loading && <Loader color='secondary' />}
      {open && isTrending && (
        <ResultSection
          title='Trending Today'
          titleImg={<img src='/icons/trending.svg' alt='trending' />}
        >
          <ResultList items={rowsTrending} />
        </ResultSection>
      )}
      {open && !isTrending && (
        <>
          {/*<ResultSection title='Category'>*/}
          {/*  <ResultList*/}
          {/*    items={[*/}
          {/*      <ResultCategory*/}
          {/*        key='art'*/}
          {/*        item={{ name: 'Art', image: '/icons/category-art.png' }}*/}
          {/*      />,*/}
          {/*    ]}*/}
          {/*  />*/}
          {/*</ResultSection>*/}
          <ResultSection title='Collections' visible={collections.length > 0}>
            <ResultList items={rowsCollections} />
          </ResultSection>
          <ResultSection title='NFTs' visible={assets.length > 0}>
            <ResultList items={rowsAssets} />
          </ResultSection>
        </>
      )}
    </div>
  )
}

export default SearchResult
