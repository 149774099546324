const SkeletonInput = ({ active, loading, className }) => {
  const classNameSkeleton =
    'skeleton-input' +
    (className ? ` ${className}` : '') +
    (loading === true ? ' skeleton-input--loading' : '') +
    (active === true && loading === true ? ' skeleton-input--active' : '')

  return <div className={classNameSkeleton} />
}

export default SkeletonInput
