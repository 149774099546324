import Link from 'next/link'

import data from './data.json'

const Navigation = ({ theme = 'dark' }) => {
  return (
    <nav className={`navigation navigation_theme_${theme}`}>
      {data.map((item, key) => (
        <Link href={item.link} key={key}>
          <a className='navigation__link'>{item.title}</a>
        </Link>
      ))}
    </nav>
  )
}

export default Navigation
