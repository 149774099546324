export const cutAddress = (address, startLetters = 3) => {
  try {
    return address.slice(0, startLetters) + '...' + address.slice(-4)
  } catch (error) {
    console.log(error)
    return address
  }
}

export const formatNumber = (num, decimals = 2) => {
  try {
    return num.toFixed(decimals)
  } catch (error) {
    console.log(error)
    return ''
  }
}

export const cutText = (str, maxLength) => {
  if (!str) return ''
  if (str.length > maxLength) {
    return str.substring(0, maxLength - 3).trim() + '...'
  }
  return str
}
