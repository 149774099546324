import { useState, useEffect } from 'react'

const LOCAL_STORAGE_KEY = 'rariscanCookieAccepted'

const isCookieAccepted = () => {
  return !!localStorage.getItem(LOCAL_STORAGE_KEY)
}

export default function useAcceptCookie() {
  const [accepted, setAccepted] = useState(true)

  useEffect(() => {
    if (typeof localStorage !== 'undefined') {
      setTimeout(() => {
        setAccepted(isCookieAccepted())
      }, 1000)
    }
  }, [])

  const acceptCookie = () => {
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem(LOCAL_STORAGE_KEY, 'true')
      setAccepted(true)
    }
  }

  return { accepted, acceptCookie }
}
