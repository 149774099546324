import { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'

import { CoreApi } from '/src/helpers/CoreApi'
import useDebounce from '/src/hooks/useDebounce'
import SearchDesktop from './SearchDesktop'
import SearchMobile from './SearchMobile'
import DeviceContext from '/src/contexts/DeviceContext'

const Search = ({ placeholder, responsive, shape, className }) => {
  const [query, setQuery] = useState('')
  const [collections, setCollections] = useState([])
  const [assets, setAssets] = useState([])
  const [trending, setTrending] = useState([])
  const [loading, setLoading] = useState(false)

  const { isMobile } = useContext(DeviceContext)
  const debouncedSearchQuery = useDebounce(query, 500)

  useEffect(() => {
    CoreApi.getCollections({ sort: 'volumeD', min: 1, size: 5 }).then(
      (result) => {
        setTrending(result)
      }
    )
  }, [])

  const fetchData = async (search) => {
    const searchCollections = search.match(/(^[^\s]+)/gis)
    return await Promise.all([
      CoreApi.getCollections({
        search: searchCollections ? searchCollections[0] : '',
        sort: 'volumeW',
        min: 1,
        size: 5,
      }),
      CoreApi.searchAssets({ s: search }),
    ])
  }

  useEffect(() => {
    if (debouncedSearchQuery && debouncedSearchQuery.length > 2) {
      setLoading(true)
      fetchData(debouncedSearchQuery).then((result) => {
        setCollections(result[0])
        setAssets(result[1].data)
        setLoading(false)
      })
    } else {
      setCollections([])
      setAssets([])
      setLoading(false)
    }
  }, [debouncedSearchQuery])

  const onChange = (e) => {
    setQuery(e.target.value)
  }

  const classNameSearch =
    '' +
    (shape ? ` search_shape_${shape}` : '') +
    (className ? ` ${className}` : '')

  return isMobile && responsive ? (
    <SearchMobile
      loading={loading}
      collections={collections}
      trending={trending}
      assets={assets}
      query={query}
      placeholder={placeholder}
      onChange={onChange}
    />
  ) : (
    <SearchDesktop
      loading={loading}
      collections={collections}
      trending={trending}
      assets={assets}
      query={query}
      placeholder={placeholder}
      onChange={onChange}
      onClear={shape ? () => setQuery('') : undefined}
      className={classNameSearch}
    />
  )
}

Search.defaultProps = {
  responsive: true,
  shape: 'circle',
}

Search.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  responsive: PropTypes.bool,
  shape: PropTypes.oneOf(['circle', 'square']),
}

export default Search
