import { useRef, useState } from 'react'

import SearchResult from './SearchResult'
import useOutsideClick from '/src/hooks/useOutsideClick'

const SearchDesktop = ({
  loading,
  query,
  onChange,
  collections,
  assets,
  trending,
  placeholder,
  className,
  onClear,
}) => {
  const [active, setActive] = useState(false)
  const [focused, setFocused] = useState(false)

  const resultOpen = (!loading && collections.length > 0) || trending.length > 0

  const searchRef = useRef(null)
  const searchInputRef = useRef(null)

  useOutsideClick(searchRef, () => setActive(false))

  const inputOnClick = (e) => {
    if (searchInputRef.current) {
      searchInputRef.current.focus()
    }
  }

  const classNameSearch = `search ${className}`
  return (
    <div className={classNameSearch} ref={searchRef} onClick={inputOnClick}>
      <div
        className={`search__input-group${
          focused ? ' search__input-group--active' : ''
        }`}
        onClick={() => setActive(true)}
      >
        <img src='/icons/search.svg' alt='search' width={16} height={16} />
        <input
          ref={searchInputRef}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          placeholder={placeholder || 'Collection, items or accounts'}
          className='search__input'
          value={query}
          onChange={onChange}
        />
        {onClear && query.length > 0 && (
          <div className='search__input-clear' onClick={onClear}>
            <div>
              <div />
              <div />
            </div>
          </div>
        )}
      </div>
      <SearchResult
        collections={collections}
        trending={trending}
        assets={assets}
        open={active && resultOpen}
        loading={loading}
      />
    </div>
  )
}

export default SearchDesktop
