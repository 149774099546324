import PropTypes from 'prop-types'

const Loader = ({ color }) => {
  const loaderClassName = 'loader' + ` loader_color_${color}`
  return (
    <div className={loaderClassName}>
      <div />
    </div>
  )
}

Loader.defaultProps = {
  color: 'primary',
}

Loader.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary']),
}

export default Loader
