import { useState } from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'
import Image from 'next/image'

import Skeleton from '/src/components/@common.blocks/Skeleton'

const Avatar = ({
  src,
  alt,
  size,
  shape,
  link,
  border,
  blueMark,
  className,
}) => {
  const [loading, setLoading] = useState(true)
  const avatarClassName =
    'avatar' +
    (size ? ` avatar_size_${size}` : '') +
    (shape ? ` avatar_shape_${shape}` : '') +
    (border ? ` avatar_border_${border}` : '') +
    (className ? ` ${className}` : '')

  let imgSrc = src || '/images/placeholder.svg'
  if (imgSrc.endsWith('=s120')) {
    imgSrc = imgSrc.replace('=s120', '=s400')
  }

  return (
    <div className={avatarClassName}>
      <div className='avatar__container'>
        {link && (
          <Link href={link} passHref>
            <a>
              <Skeleton loading={loading}>
                <Image
                  src={imgSrc}
                  alt={alt}
                  layout='fill'
                  className='avatar__image'
                  onLoadingComplete={() => setLoading(false)}
                />
              </Skeleton>
            </a>
          </Link>
        )}
        {!link && (
          <Skeleton loading={loading}>
            <Image
              src={imgSrc}
              alt={alt}
              layout='fill'
              className='avatar__image'
              onLoadingComplete={() => setLoading(false)}
            />
          </Skeleton>
        )}
      </div>
      {blueMark === true && (
        <img
          className='avatar__blueMark'
          src='/icons/bluemark.svg'
          alt='verified'
        />
      )}
    </div>
  )
}

Avatar.defaultProps = {
  size: 'md',
  shape: 'circle',
  blueMark: false,
  border: 'light',
}

Avatar.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  shape: PropTypes.oneOf(['circle', 'square']),
  border: PropTypes.oneOf(['light', 'dark', 'white']),
  link: PropTypes.string,
  blueMark: PropTypes.bool,
  className: PropTypes.string,
}

export default Avatar
