import PropTypes from 'prop-types'

const Input = ({
  type,
  placeholder,
  name,
  value,
  required,
  disabled,
  className,
  variant,
  autoFocus,
  icon,
  onChange,
  onFocus,
  onBlur,
  onClear,
}) => {
  const inputClassName =
    `input-field${required ? ' input-field--required' : ''}` +
    (disabled === true ? ' input-field--disabled' : '') +
    ` input-field_variant_${variant}` +
    (className ? ` ${className}` : '')

  return (
    <div className={inputClassName}>
      {icon && <div className='input__icon'>{icon}</div>}
      <input
        className='input'
        type={type}
        name={name}
        value={value || ''}
        placeholder={placeholder}
        required={required}
        onChange={onChange}
        disabled={disabled}
        autoFocus={autoFocus}
        onBlur={onBlur}
        onFocus={onFocus}
      />
      {onClear && value && (
        <div className='input__clear' onClick={onClear}>
          <div>
            <div />
            <div />
          </div>
        </div>
      )}
    </div>
  )
}

Input.defaultProps = {
  type: 'text',
  placeholder: '',
  required: false,
  variant: 'default',
  autoFocus: false,
}

Input.propTypes = {
  autoFocus: PropTypes.bool,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  required: PropTypes.bool,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'search']),
  icon: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onClear: PropTypes.func,
}

export default Input
