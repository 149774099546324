const onMenuOpen = (isOpen) => {
  if (!document) return
  if (isOpen) {
    document.getElementsByTagName('html')[0].style.overflow = 'hidden'
    document.getElementsByTagName('html')[0].style.height = '100vh'
  } else {
    document.getElementsByTagName('html')[0].style.overflow = ''
    document.getElementsByTagName('html')[0].style.height = ''
  }
}

export default onMenuOpen
