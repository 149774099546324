import React from 'react'
import Link from 'next/link'
import getConfig from 'next/config'
import { useRouter } from 'next/router'

import Container from '/src/components/@common.blocks/Container'
import Text from '/src/components/@common.blocks/Text'
import Divider from '/src/components/@common.blocks/Divider'

import links from '/src/components/@common.blocks/Navigation/data.json'

const { publicRuntimeConfig } = getConfig()
const socialLinks = publicRuntimeConfig.socialLinks || {}

const NavigationMobile = ({ menuOnClose }) => {
  const router = useRouter()
  const onNavigate = async (e, href) => {
    await router.replace(href)
    if (menuOnClose) menuOnClose()
  }
  return (
    <nav className='navigation-mobile'>
      <Container className='navigation-mobile__container'>
        <nav className='navigation-mobile__navigation'>
          <Text color='gray-03' size='lg' weight='medium'>
            Main
          </Text>
          <ul className='navigation-mobile__list'>
            {links.map((item, key) => (
              <li key={key}>
                <Link href={item.link}>
                  <a
                    className='navigation-mobile__link'
                    onClick={(e) => onNavigate(e, item.link)}
                  >
                    {item.title}
                  </a>
                </Link>
              </li>
            ))}
          </ul>
          <Text color='gray-03' size='lg' weight='medium'>
            Company
          </Text>
          <ul className='navigation-mobile__list'>
            <li>
              <a
                className='navigation-mobile__link'
                onClick={(e) => onNavigate(e, '/for-media')}
              >
                For media
              </a>
            </li>
            <li>
              <a
                className='navigation-mobile__link'
                onClick={(e) => onNavigate(e, '/faq')}
              >
                FAQ
              </a>
            </li>
            <li>
              <a className='navigation-mobile__link'>API documentation</a>
            </li>
          </ul>
        </nav>
        <div className='navigation-mobile__footer'>
          <Container>
            <Text color='gradient' size='lg' weight='medium'>
              Join the community
            </Text>
            <div className='navigation-mobile__social-links'>
              <a
                className='button button_color_light button_size_md'
                href={socialLinks.twitter}
                rel='noreferrer'
                target='_blank'
              >
                <img src='/icons/twitter-black.svg' alt='twitter' />
                Twitter
              </a>
              <a
                className='button button_color_light button_size_md'
                href={socialLinks.discord}
                rel='noreferrer'
                target='_blank'
              >
                <img src='/icons/discord-black.svg' alt='discord' />
                Discord
              </a>
            </div>
            <Divider />
          </Container>
        </div>
      </Container>
    </nav>
  )
}

export default NavigationMobile
