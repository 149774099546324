import EthereumIcon from '/public/ui-icons/ethereum.svg'
import SolanaIcon from '/public/ui-icons/solana.svg'
import PolygonIcon from '/public/ui-icons/polygon.svg'
import CardanoIcon from '/public/ui-icons/cardano.svg'
import BSCIcon from '/public/ui-icons/bsc.svg'

export default {
  ethereum: EthereumIcon,
  solana: SolanaIcon,
  polygon: PolygonIcon,
  cardano: CardanoIcon,
  bsc: BSCIcon,
}
