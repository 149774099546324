import PropTypes from 'prop-types'
import Head from 'next/head'

import { cutText } from '/src/helpers/formatter'

const SEO = ({ title, description, image }) => {
  const metaDescription = cutText(description, 155) || title
  return (
    <Head>
      <title>{`${title} | Rariscan`}</title>

      <meta name='viewport' content='width=device-width, initial-scale=1' />

      <meta name='description' content={metaDescription} />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={metaDescription} />
      <meta property='og:type' content='website' />
      <meta property='og:site_name' content='Rariscan' />

      {image && (
        <>
          <meta property='og:image' content={image} />
          <meta property='og:image:height' content='630' />
          <meta property='og:image:width' content='1200' />

          <meta property='twitter:image' content={image} />
          <meta name='twitter:card' content='summary_large_image' />
        </>
      )}
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={metaDescription} />

      <meta name='keywords' content='Rariscan, rarity, NFT rarity' />

      <link
        rel='apple-touch-icon'
        sizes='180x180'
        href='/favicon/apple-touch-icon.png'
      />
      <link rel='icon' type='image/png' href='/favicon/favicon.ico' />
      <link
        rel='icon'
        type='image/png'
        sizes='32x32'
        href='/favicon/favicon-32x32.png'
      />
      <link
        rel='icon'
        type='image/png'
        sizes='16x16'
        href='/favicon/favicon-16x16.png'
      />
      <link rel='manifest' href='/favicon/site.webmanifest' />
      <link
        rel='mask-icon'
        href='/favicon/safari-pinned-tab.svg'
        color='#5bbad5'
      />
      <meta name='msapplication-TileColor' content='#da532c' />
      <meta name='theme-color' content='#ffffff' />
    </Head>
  )
}

SEO.defaultProps = {
  title: 'Check NFT Ranking',
}

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

export default SEO
