import Link from 'next/link'
import getConfig from 'next/config'

import Container from '/src/components/@common.blocks/Container'
import Text from '/src/components/@common.blocks/Text'

const { publicRuntimeConfig } = getConfig()
const socialLinks = publicRuntimeConfig.socialLinks || {}

const Footer = () => {
  return (
    <footer className='footer'>
      <Container>
        <div className='footer__row'>
          <div className='footer__logo'>
            <img src='/logo-label.svg' alt='rariscan' />
          </div>
          <div className='footer__grid'>
            <div className='footer__grid-item'>
              <Text weight='semi-bold'>Main</Text>
              <ul className='footer__navigation'>
                <li>
                  <Link href='/collections'>
                    <a className='footer__nav-link'>Collections</a>
                  </Link>
                </li>
                <li>
                  <Link href='/collections'>
                    <a className='footer__nav-link'>Upcoming</a>
                  </Link>
                </li>
                <li className='footer__nav-link'>
                  <Link href='/get-listed'>
                    <a className='footer__nav-link'>List Your Project</a>
                  </Link>
                </li>
              </ul>
            </div>
            <div className='footer__grid-item'>
              <Text weight='semi-bold'>Company</Text>
              <ul className='footer__navigation'>
                <li>
                  <Link href='/for-media'>
                    <a className='footer__nav-link'>For Media</a>
                  </Link>
                </li>
                <li>
                  <a
                    className='footer__nav-link'
                    href='https://docs.rariscan.io/rariscan/faq'
                    rel='noreferrer'
                    target='_blank'
                  >
                    FAQ
                  </a>
                </li>
                <li className='footer__nav-link'>API Documentation</li>
              </ul>
            </div>
            <div className='footer__grid-item'>
              <Text weight='semi-bold'>Contact Us</Text>
              <ul className='footer__navigation'>
                <li>
                  <a
                    href='mailto:support@rariscan.io'
                    target='_blank'
                    rel='noreferrer'
                    className='footer__nav-link'
                  >
                    support@rariscan.io
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className='footer__socials'>
            <Text weight='semi-bold'>Follow Us</Text>
            <div className='footer__socials-links'>
              <a
                href={socialLinks.twitter}
                rel='noreferrer'
                target='_blank'
                className='footer__socials-link'
                aria-label='Twitter'
              >
                <img
                  src='/icons/twitter.svg'
                  alt='twitter'
                  height={18}
                  width={18}
                />
              </a>
              <a
                // href={socialLinks.discord}
                rel='noreferrer'
                target='_blank'
                className='footer__socials-link footer__socials-link--disabled'
                aria-label='Discord'
              >
                <img
                  src='/icons/discord.svg'
                  alt='discord'
                  height={18}
                  width={18}
                />
              </a>
              <div className='footer__socials-link footer__socials-link--disabled'>
                <img
                  src='/icons/instagram.svg'
                  alt='instagram'
                  height={18}
                  width={18}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='footer__bottom'>
          <Text color='light-gray' className='footer__copyright'>
            © Rariscan, Inc. All rights reserved.
          </Text>
          <div className='footer__legal'>
            <Text color='light-gray' component='span'>
              <a
                href='https://docs.rariscan.io/legal/cookie-policy'
                target='_blank'
                rel='noreferrer'
              >
                Cookie Policy
              </a>
            </Text>
            <Text color='light-gray' component='span'>
              <a
                href='https://docs.rariscan.io/legal/terms-of-service'
                target='_blank'
                rel='noreferrer'
              >
                Terms of Service
              </a>
            </Text>
          </div>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
