import PropTypes from 'prop-types'

const Container = ({ children, className, size, ...props }) => {
  const classNameHeader =
    'container' +
    (size ? ` container_size_${size}` : '') +
    (className ? ` ${className}` : '')

  return (
    <div className={classNameHeader} {...props}>
      {children}
    </div>
  )
}

Container.defaultProps = {
  size: 'md',
}

Container.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  size: PropTypes.oneOf(['xs', 'sm', 'md']),
}

export default Container
