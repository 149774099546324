import axios from 'axios'
import getConfig from 'next/config'
import { getSession } from '/src/helpers/auth'

const { publicRuntimeConfig } = getConfig()

const fetchApi = axios.create({
  baseURL: publicRuntimeConfig.apiBaseUrl,
  headers: { 'Content-Type': 'application/json' },
})

fetchApi.interceptors.request.use(async (reqConfig) => {
  return reqConfig
})

export const stringifyParams = (params = {}) => {
  let query = Object.keys(params)
    .filter((key) => typeof params[key] !== 'undefined')
    .map((key) => `${key}=${params[key]}`)
    .join('&')
  return query.length > 0 ? `?${query}` : ''
}

export class CoreApi {
  static async walletConnect(address) {
    const response = await fetchApi.post(`/auth/wallet/${address}/connect`)
    return { ...response.data }
  }

  static async walletSignIn(address, signature) {
    const response = await fetchApi.post(`/auth/wallet/${address}/signIn`, {
      signature,
    })
    return { ...response.data }
  }

  static async walletRefreshToken(refreshToken) {
    const response = await fetchApi.post(`/auth/wallet/refreshToken`, {
      refreshToken,
    })
    return { ...response.data }
  }

  static async getCollections(params) {
    const query = stringifyParams(params)
    const response = await fetchApi.get(`/collections${query}`)
    return [...response.data]
  }

  static async getCollectionBySlug(slug) {
    const response = await fetchApi.get(`/collections/${slug}`)
    return { ...response.data }
  }

  static async createCollection(data) {
    try {
      const { accessToken } = await getSession()
      const response = await fetchApi.post('/collections/create', data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      return { ...response.data }
    } catch (error) {
      if (error.response) {
        throw new Error(error.response.data.message || error.message)
      } else {
        throw new Error(error.message)
      }
    }
  }

  static async updateCollectionStatus(accessToken, slug, status) {
    try {
      const { data } = await fetchApi.patch(
        `/collections/${slug}/status`,
        { status: 'published' },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      return { ...data }
    } catch (error) {
      return { error, status }
    }
  }

  static async getAssets(params) {
    try {
      const query = stringifyParams(params)
      const response = await fetchApi.get(`/assets${query}`)
      return {
        data: [...response.data],
        total: Number(response.headers['x-total']),
        totalPages: Number(response.headers['x-total-pages']),
      }
    } catch (error) {
      return { data: [], total: 0, totalPages: 0 }
    }
  }

  static async getAssetByTokenId(collection, tokenId) {
    try {
      const { data } = await fetchApi.get(`/assets/${collection}/${tokenId}`)
      return data
    } catch (error) {
      return null
    }
  }

  static async searchAssets(params) {
    try {
      const query = stringifyParams(params)
      const response = await fetchApi.get(`/assets/search${query}`)
      return {
        data: [...response.data],
        total: Number(response.headers['x-total']),
        totalPages: Number(response.headers['x-total-pages']),
      }
    } catch (error) {
      return { data: [], total: 0, totalPages: 0 }
    }
  }

  static async getUserInfo(accessToken) {
    try {
      const response = await fetchApi.get('/users/info', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      return { ...response.data }
    } catch (error) {
      console.log(error)
      return {}
    }
  }

  static async updateUserInfo(data) {
    const { accessToken } = await getSession()
    const response = await fetchApi.patch(
      '/users/info',
      { ...data },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    return { ...response.data }
  }

  static async getUserFavorites(accessToken) {
    try {
      const response = await fetchApi.get('/userFavorites', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      return [...response.data]
    } catch (error) {
      console.log(error)
      return []
    }
  }

  static async getUserCollections(accessToken, params = {}) {
    try {
      const query = stringifyParams(params)
      const response = await fetchApi.get(`/collections/my${query}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      return [...response.data]
    } catch (error) {
      console.log(error)
      return []
    }
  }

  static async getUpcomingCollections(params) {
    try {
      const query = stringifyParams(params)
      const response = await fetchApi.get(`/upcomingCollections${query}`)
      return [...response.data]
    } catch (e) {
      console.log(e)
      return []
    }
  }

  static async getMessageForSignWS(accessToken) {
    const response = await fetchApi.post(
      `/whitelistSpots/msgForSign`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    return { ...response.data }
  }

  static async joinWhitelist(accessToken, data) {
    const response = await fetchApi.post(
      `/whitelistSpots/create`,
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    return { ...response.data }
  }

  static async getTotalByAddressWS(accessToken) {
    const response = await fetchApi.get(`/whitelistSpots/totalByAddress`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    return { ...response.data }
  }

  static async getTotalParticipantsWS(accessToken) {
    const response = await fetchApi.get(`/whitelistSpots/total`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    return { ...response.data }
  }
}
