import useAcceptCookie from '/src/hooks/useAcceptCookie'

const CookieMessage = () => {
  const { accepted, acceptCookie } = useAcceptCookie()

  return (
    <div
      className={`cookie-message${!accepted ? ' cookie-message--show' : ''}`}
    >
      <div className='cookie-message__wrapper'>
        <span className='cookie-message__text'>
          This website used cookies.{' '}
          <a href='/cookie-policy' target='_blank' rel='noreferrer'>
            Learn more
          </a>
        </span>
        <button
          className='button cookie-message__button'
          onClick={acceptCookie}
        >
          Okay
        </button>
      </div>
    </div>
  )
}

export default CookieMessage
